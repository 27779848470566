<template>
  <div class="row table-sm mr-0 ml-0 p-0">
    <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
      <div
        class="col-7 p-0 align-self-center font-weight-bold d-flex align-items-center"
      >
        <h5 class="d-inline mr-2 font-weight-bold">
          {{ $t("message.district") }}
        </h5>
        <crm-refresh @c-click="refresh()"></crm-refresh>
        <div class="text-center d-flex sorddata ml-3">
          <el-input
            size="mini"
            placeholder="Найти"
            prefix-icon="el-icon-search"
            v-model="filterForm.search"
            clearable
          ></el-input>
        </div>
      </div>
      <div
        class="col-5 align-items-center align-self-center text-right pr-0 d-flex justify-content-end"
      >
        <el-button
          v-can="'cities.create'"
          size="mini"
          @click="drawerCreate = true"
          icon="el-icon-circle-plus-outline"
        >
          {{ $t("message.create") }}
        </el-button>
        <export-excel
          v-can="'cities.excel'"
          class="btn excel_btn"
          :data="list"
          :fields="excel_fields"
          @fetch="controlExcelData()"
          worksheet="Область"
          name="Область.xls"
        >
          <el-button size="mini">
            <i class="el-icon-document-delete"></i> Excel
          </el-button>
        </export-excel>
      </div>
    </div>
    <table class="table table-bordered table-hover" v-loading="loadingData">
      <crm-pagination
        :pagination="pagination"
        @c-change="updatePagination"
      ></crm-pagination>
      <thead>
        <tr>
          <crm-th
            :column="columns.id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.name"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.city_id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :sort="sort"
            :column="columns.updated_at"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :sort="sort"
            :column="columns.created_at"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :sort="sort"
            :column="columns.settings"
            @c-change="updateSort"
          ></crm-th>
        </tr>
        <tr>
          <th v-if="columns.id.show">
            <el-input
              clearable
              size="mini"
              class="id_input"
              v-model="filterForm.id"
              :placeholder="columns.id.title"
            ></el-input>
          </th>
          <th v-if="columns.name.show">
            <el-input
              size="mini"
              v-model="filterForm.name"
              :placeholder="columns.name.title"
              clearable
            >
            </el-input>
          </th>
          <th v-if="columns.city_id.show">
            <el-select
              filterable
              clearable
              :placeholder="columns.city_id.title"
              size="mini"
              v-model="filterForm.city_id"
              remote
              :remote-method="searchCitiesLoad"
            >
              <el-option
                v-for="item in cities"
                :key="item.name + item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </th>
          <th v-if="columns.updated_at.show">
            <el-date-picker
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              v-model="filterForm.updated_at"
              :placeholder="columns.updated_at.title"
              size="mini"
            >
            </el-date-picker>
          </th>
          <th v-if="columns.created_at.show">
            <el-date-picker
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              v-model="filterForm.created_at"
              :placeholder="columns.created_at.title"
              size="mini"
            >
            </el-date-picker>
          </th>
          <th v-if="columns.settings.show"></th>
        </tr>
      </thead>

      <transition-group name="flip-list" tag="tbody">
        <tr v-for="city in list" :key="city.id" class="cursor-pointer">
          <td v-if="columns.id.show">{{ city.id }}</td>
          <td v-if="columns.name.show">{{ city.name }}</td>
          <td v-if="columns.city_id.show">
            {{ city.city ? city.city.name : "" }}
          </td>
          <td v-if="columns.updated_at.show">
            {{ city.updated_at }}
          </td>
          <td v-if="columns.created_at.show">
            {{ city.created_at }}
          </td>
          <td v-if="columns.settings.show" class="settings-td">
            <crm-setting-dropdown
              :model="city"
              name="cities"
              :actions="actions"
              @edit="edit"
              @delete="destroy"
            >
            </crm-setting-dropdown>
          </td>
        </tr>
      </transition-group>
    </table>
    <el-drawer
      title="Новый тег"
      :visible.sync="drawerCreate"
      size="60%"
      :drawer="drawerCreate"
      @close="reloadIfChanged"
    >
      <div>
        <crm-create @c-close="closeDrawer"></crm-create>
      </div>
    </el-drawer>
    <el-drawer
      title="Изменить тег"
      :visible.sync="drawerUpdate"
      size="60%"
      :drawer="drawerUpdate"
      @open="reopenUpdate = true"
      @close="reopenUpdate = false"
      @closed="emptyModel"
    >
      <div>
        <crm-update
          :reloadModel="reopenUpdate"
          @open="reopenUpdate = true"
          @c-close="reloadIfChanged('drawerUpdate')"
          @closed="emptyModel"
        ></crm-update>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import list from "@/utils/mixins/list";
import _ from "lodash";
export default {
  mixins: [list],
  name: "CitiesList",
  components: {
    CrmUpdate,
    CrmCreate,
  },
  data() {
    return {
      drawerCreate: false,
      drawerUpdate: false,
      reopenUpdate: false,
      excel_data: [],
      excel_fields: {},
      loadingData: false,
    };
  },
  computed: {
    ...mapGetters({
      list: "districts/list",
      columns: "districts/columns",
      pagination: "districts/pagination",
      filter: "districts/filter",
      sort: "districts/sort",
      cities: "cities/list",
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  async created() {},
  async mounted() {
    this.controlExcelData();

    if (this.cities && this.cities.length === 0) await this.loadcities();
  },
  watch: {
    columns: {
      handler: function () {
        this.controlExcelData();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      updateList: "districts/index",
      updateSort: "districts/updateSort",
      updateFilter: "districts/updateFilter",
      updateColumn: "districts/updateColumn",
      updatePagination: "districts/updatePagination",
      editModel: "districts/show",
      empty: "districts/empty",
      delete: "districts/destroy",
      refreshData: "districts/refreshData",
      loadcities: "cities/index",
    }),
    searchCitiesLoad(val) {
      this.searchCities(val);
    },
    searchCities: _.debounce(function (val) {
      this.loadcities({ search: val });
    }, 500),
    fetchData() {
      const query = { ...this.filter, ...this.pagination, ...this.sort };
      if (!this.loadingData) {
        this.loadingData = true;
        this.updateList(query)
          .then((res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
    },
    refresh() {
      this.refreshData()
        .then((res) => {
          this.filterForm = JSON.parse(JSON.stringify(this.filter));
        })
        .catch((err) => {});
    },
    async edit(model) {
      await this.editModel(model.id)
        .then(async (res) => {
          this.drawerUpdate = true;
        })
        .catch((err) => {});
    },
    async show(model) {
      await this.showModel(model.id)
        .then((res) => {
          this.drawerShow = true;
        })
        .catch((err) => {});
    },
    controlExcelData() {
      this.excel_fields = {};
      for (let key in this.columns) {
        if (this.columns.hasOwnProperty(key)) {
          let column = this.columns[key];
          if (column.show && column.column !== "settings") {
            this.excel_fields[column.title] = column.column;
          }
        }
      }
    },
    destroy(model) {
      this.delete(model.id)
        .then((res) => {
          this.$alert(res);
          // this.fetchData()
        })
        .catch((err) => {
          console.log(err);
        });
    },
    emptyModel() {
      this.empty();
    },
  },
};
</script>
